import axios, { type AxiosResponse } from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { StatusCodes } from 'http-status-codes'
import isEmpty from 'lodash/isEmpty'
import { StatusCodes as HttpStatusCodes } from 'http-status-codes/build/cjs/status-codes'
import { useNotificationsStore } from '@js/stores/notifications'
import { createErrorLocation } from '@js/router/helpers'
import Translator from '@js/translator'
import { useAuthStore } from '@js/stores/auth'
import type { HydraErrorResponse } from '@js/types'

export default function useHandleAxiosErrorResponse() {
  const router = useRouter()
  const route = useRoute()
  const authStore = useAuthStore()
  const notificationsStore = useNotificationsStore()

  function handle(response: AxiosResponse) {
    switch (response.status) {
      case StatusCodes.INTERNAL_SERVER_ERROR:
        return router
          .push(createErrorLocation(route, StatusCodes.INTERNAL_SERVER_ERROR))
          .then(() => true)
      case StatusCodes.FORBIDDEN:
        return router.push(createErrorLocation(route, StatusCodes.FORBIDDEN)).then(() => true)
      case StatusCodes.NOT_FOUND:
        return router.push(createErrorLocation(route, StatusCodes.NOT_FOUND)).then(() => true)
      default:
        return Promise.resolve(false)
    }
  }

  /**
   * @param error
   * @param overrideMethod - If true is returned, the notification are deemed as resolved and no further action will be taken.
   */
  async function resolveNotification(
    error: unknown,
    overrideMethod?: (errorResponse: AxiosResponse | HydraErrorResponse) => Promise<boolean>
  ) {
    if (!axios.isAxiosError(error) || !error.response) {
      throw error
    }

    const errorResponse = error.response
    if (overrideMethod && (await overrideMethod(errorResponse))) {
      return
    }

    // The messages were passed in the response
    if (errorResponse.data.messages && !isEmpty(errorResponse.data.messages)) {
      notificationsStore.addByType(errorResponse.data.messages)
      return
    }

    switch (errorResponse.status) {
      case HttpStatusCodes.BAD_REQUEST:
        notificationsStore.addError(Translator.trans('u2.http_400'))

        throw error
      case HttpStatusCodes.UNAUTHORIZED:
        notificationsStore.addError(
          authStore.user ? Translator.trans('u2.session_expired') : Translator.trans('u2.http_401')
        )
        break
      case HttpStatusCodes.FORBIDDEN:
        if (errorResponse.data['description'] !== undefined) {
          notificationsStore.addError(errorResponse.data['description'])
          break
        }
        notificationsStore.addError(Translator.trans('u2.http_403'))
        break
      case HttpStatusCodes.NOT_FOUND:
        notificationsStore.addError(Translator.trans('u2.http_404'))
        break
      case HttpStatusCodes.METHOD_NOT_ALLOWED:
        notificationsStore.addError(Translator.trans('u2.http_405'))
        throw error
      case HttpStatusCodes.REQUEST_TIMEOUT:
        notificationsStore.addError(Translator.trans('u2.http_408'))
        throw error
      case HttpStatusCodes.CONFLICT:
        if (errorResponse.data['description'] === 'Cannot delete due to associated data') {
          notificationsStore.addError(
            Translator.trans('u2_core.delete.cannot_delete_due_to_associated_data')
          )
          break
        }
        notificationsStore.addError(Translator.trans('u2.http_409'))
        throw error
      case HttpStatusCodes.MISDIRECTED_REQUEST:
        // The user will be redirected to a 404 page. No extra message is needed
        break
      case HttpStatusCodes.UNPROCESSABLE_ENTITY:
        // if (errorResponse.data.violations === undefined) {
        notificationsStore.addError(Translator.trans('u2.http_422'))
        // }
        break
      case HttpStatusCodes.SERVICE_UNAVAILABLE:
        // The user will be redirected to a 503 page. No extra message is needed
        break
      default:
        notificationsStore.addError(Translator.trans('u2.http_500'))
    }
  }

  return {
    handle,
    resolveNotification,
  }
}
